<template>
<div class=" wrapper"   v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="top-header">
        <div>
            <h1> {{$route.params.type=='1,2'? $t('sustainable_buildings_list'): $route.params.type=='3'? $t('not_sustainable_buildings_list'): $t('not_sustainable_buildings4_list') }}</h1>
        </div>
        <div class="flex align-center">
            <el-button v-if="!select_invest && ['operator'].includes(role)" class="primary-btn " @click="select_invest=true; is_invest=false; __GET()"> <i class="el-icon-finished mr-1"></i> {{ $t('add_invest_project') }} </el-button>
            <el-button class="dark-btn" @click="$router.push('/')">{{$t('back')}}</el-button>
        </div>
    </div>
    <div class="wp-body obj-list">
        <header class="wp-header flex flex-col ">
            <div class="cover flex justify-between items-center mb-3 pr-3 w-full">
                <div>
                    <p class="fw-600 fs-20 c-dark-blue">{{$t('building_list')}}:</p>
                </div>
                <div class="flex items-center justify-end  bb fw-w" style="flex:1; padding-left:50px;">
                    <el-select :placeholder="$t('region')" class="mt-2 mb-2 mw-100" v-if="['admin', 'org_admin', 'operator', 'ministry_admin'].includes(role)" v-model="region" style="margin-right: 5px; width:25%;" @change="regionChanged(); __GET(); " clearable>
                        <el-option v-for="item in regions" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.shared_id">
                        </el-option>
                    </el-select>
                    <el-select :placeholder="$t('district')" class="mt-2 mb-2 mw-100" v-if="['admin', 'org_admin', 'operator', 'ministry_admin'].includes(role)" v-model="district" @change="ChangeDistrict(), __GET()" :disabled="!region" style=" margin-right: 5px; width:25%;" clearable>
                        <el-option v-for="item in districts" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                        </el-option>
                    </el-select>
                    <el-select :placeholder="$t('building_category')" class="mt-2 mb-2 mw-100" v-if="['admin', 'org_admin', 'operator', 'ministry_admin'].includes(role)" @change="__GET()" v-model="buildinggroup" style="margin-right: 5px; width:25%;" clearable>
                        <el-option v-for="item in building_group" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                        </el-option>
                    </el-select>
                    <div style="flex: 1; width:25%;" class="flex items-center justify-end mt-2 mb-2 mw-100">
                        <el-input class="search" style="margin-left: 0 !important;" :placeholder="$t('search')" prefix-icon="el-icon-search" v-model="search" @keyup.enter.native="__GET()" clearable>
                        </el-input>
                        <el-button @click="__GET()"  icon="el-icon-search" class="ml-2 el-button-download " size="small" circle></el-button>
                    </div>
                </div>
            </div>
        </header>

        <el-table ref="multipleTable" :data="buildsList" class="mt-5" style="width: 100%; margin-bottom: 20px;" row-key="id" @selection-change="handleSelectionChange" stripe default-expand-all>
            <el-table-column v-if="select_invest && ['operator'].includes(role)"  type="selection" width="50"  align="center"> </el-table-column>
            <el-table-column label="№" width="50" align="center">
                <template slot-scope="scope">
                    {{ (current_page-1)*item_count + (scope.$index + 1) }}
                </template>
            </el-table-column>
            <el-table-column :label="$t('building_name')" align="center" width="150" sortable>
                <template slot-scope="scope">
                    <button @click="routeTo(scope.row, 0)" class="table-link-button"> {{scope.row.name_cyr?scope.row.name_cyr : '***' }} </button>
                </template>
            </el-table-column>
            <el-table-column v-if="me.role.name == 'admin'" prop="cadastral_number" :label="$t('cadaster_number')" align="center" sortable>
            </el-table-column>
            <el-table-column :label="$t('building_address')" prop="address" align="center" sortable>
            </el-table-column>
            <el-table-column :label="$t('created_at')" prop="created_at" align="center" sortable>
            </el-table-column>
            <el-table-column :label="$t('year_construction')" prop="year_construction" align="center" sortable>
            </el-table-column>
            <el-table-column prop="finalconclusion" :label="$t('finalconclusion')" align="center" sortable>
                <template slot-scope="scope">
                    <p :class="parseInt(scope.row.finalconclusion) > 2 ? parseInt(scope.row.finalconclusion) == 3 ? 'c-yellow' : 'c-red':'c-green'">{{scope.row.finalconclusion}}</p>
                </template>
            </el-table-column>
            <el-table-column :label="$t('building_type')" prop="" align="center" sortable width="130px">
                <template slot-scope="scope">
                    <el-tag :class="buildingtype === 'NEW' ? 'new_build' : 'exist_build'" style="width: 120px; cursor: pointer" size="small" :type="buildingtype === 'NEW' ? 'success' : 'info'">{{ scope.row.buildingtype === 'NEW' ? $t('new_building') : $t('existing_building') }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column :label="$t('investment_project')" prop="is_invest"  align="center"  width="130px">
              <template slot-scope="scope">
                <InvestmentSwitch v-if="scope.row.buildingtype !== 'NEW'" v-model="scope.row.is_invest" :id="scope.row.id" :disabled="role!='operator'" />
              </template>
            </el-table-column>
            <el-table-column :label="$t('created_by')" align="center" sortable>
                <template slot-scope="scope">
                    <div class="table-link-button" @click="UserModal(scope.row.created_by)">
                        {{scope.row.created_by!=null && scope.row.created_by.profile!=null ? scope.row.created_by.profile.name+" "+scope.row.created_by.profile.surname : '-' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('updated_by')" align="center" sortable>
                <template slot-scope="scope">
                    <div class="table-link-button" @click="UserModal(scope.row.updated_by)">
                        {{scope.row.updated_by!=null && scope.row.updated_by.profile!=null ? scope.row.updated_by.profile.name +" "+ scope.row.updated_by.profile.surname : '-' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="role == 'admin' ?  $t('passport_one')+' / '+$t('statistic'):$t('statistic')" width="180" align="center">
                <template slot-scope="scope">
                    <div class="flex justify-center">
                        <div v-if="role == 'admin'">
                            <el-button   @click="GeneratePasport(scope.row.id)" class="el-button-download el-icon-s-order">
                            </el-button>
                        </div>
                        <button class="ml-2 el-button-download el-icon-download" size="small" @click="DownloadExel(scope.row.id, scope.row.name_cyr)">
                        </button>
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="center" :label="$t('change')" v-if="role!='admin' && role !== 'org_admin' ">
                <template slot-scope="scope">
                    <div class="d-flex justify-center" v-if="!(role=='operator' && scope.row.buildingtype == 'NEW')">
                        <el-button @click="routeTo(scope.row, '1')" size="small"  class="el-icon-edit el-button-download"></el-button>
                    </div>
                </template>
            </el-table-column>

        </el-table>
        <div v-if="['operator'].includes(role)" class="flex justify-end  " style="margin-top:30px; margin-bottom:20px">
            <el-button @click="select_invest=false; is_invest=''; __GET()" v-if="select_invest"  > <i class="el-icon-check mr-1"></i> {{$t('cancel')}} </el-button>
            <el-button @click="SaveMultipleSelection()" v-if="select_invest" class="primary-btn"> <i class="el-icon-check mr-1"></i> {{$t('save')}} </el-button>
        </div>
        <el-pagination v-if="!select_invest" style="margin-left: auto; margin-top: auto" class="ml-auto" :page-sizes="[10, 20, 50, 100]" :page-size="5" @current-change="paginate" @size-change="paginateSize" :current-page.sync="current_page1" :hide-on-single-page="false" background layout="sizes, prev, pager, next" :page-count="page_count">
        </el-pagination>

        <el-drawer :title="$t('building_passport')" :visible.sync="drawer" direction='rtl'>
            <div>
                <div class="pdf">
                    <vue-pdf ref="print" :src="pdf"></vue-pdf>
                    <div class="loading-pdf" v-show="loadDawer">
                        <i class="el-icon-loading"></i>
                    </div>
                </div>
                <div class="w-full pdf-control">
                    <button class="button dark-yellow" @click="downloadFile(pdf)"><i class="el-icon-download mr-2"></i> {{$t('download')}}</button>
                    <a :href="pdf" target="_blank">
                        <button class="ml-3 button dark-blue"><i class="el-icon-view mr-2"></i> {{$t('see')}} </button>
                    </a>
                </div>
            </div>
        </el-drawer>
        <div class="modal fs-14" v-if="usermodal">
            <div class="modal-cover"></div>
            <div class="modal-item" style="max-width:500px;">
                <div class="flex justify-between">
                    <p class="modal-title ">{{$t('user_date')}}:</p>
                    <button style="font-size:16px; " @click="usermodal=false"><i class="el-icon-close" style="font-weight:600;"></i> </button>
                </div>
                <el-row :gutter="24">
                    <el-col :md="12" class="fw-600 mb-2">{{$t('first_name')}}:</el-col>
                    <el-col :md="12" class="mb-2">{{userData.profile.name}}</el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :md="12" class="fw-600 mb-2">{{$t('last_name')}}:</el-col>
                    <el-col :md="12" class="mb-2">{{userData.profile.surname}}</el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :md="12" class="fw-600 mb-2">{{$t('mid_name')}}:</el-col>
                    <el-col :md="12" class="mb-2">{{userData.profile.middlename}}</el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :md="12" class="fw-600 mb-2">{{$t('phone_munber')}}:</el-col>
                    <el-col :md="12" class="mb-2">{{userData.profile.phone_number}}</el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :md="12" class="fw-600 mb-2">{{$t('user_email')}}:</el-col>
                    <el-col :md="12" class="mb-2">{{userData.profile.email ? userData.profile.email : '-'}}</el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :md="12" class="fw-600 mb-2">{{$t('organisation_name')}}:</el-col>
                    <el-col :md="12" class="mb-2">{{userData.organization && userData.organization.name_cyr ? userData.organization.name_cyr : '-'}}</el-col>
                </el-row>

                <div class="w-full modal-inputes flex justify-end">
                    <button @click="usermodal=false" style="padding:15px 15px;"> <i class="el-icon-close mr-2"></i> {{$t('close')}}</button>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import Moment from 'moment';
import {
    extendMoment
} from 'moment-range';
import InvestmentSwitch from "@/components/InvestmentSwitch";
const moment = extendMoment(Moment);
export default {
    components:{InvestmentSwitch},
    data() {
        return {
            input2: null,
            drawer: false,
            pdf: '',
            namePdf: '',
            loadDawer: false,
            page_count: 0,
            buildsList: [],
            region: '',
            district: '',
            buildinggroup: '',
            current_district: [],
            objInfo: {},
            search: '',
            item_count: 10,
            is_invest:"",
            loading: false,
            current_page: 1,
            current_page1: 1,
            buildingtype: '',
            districts: [],
            usermodal: false,
            userData: {
                organization: {},
                profile: {}
            },
            select_invest:false,
            multipleSelection:[]
        };
    },
    computed: {
        me() {
            return this.$store.state.me;
        },
        role() {
            return this.$store.state.role;
        },
        regions() {
            return this.$store.state.dictionary.region;
        },
        building_group() {
            return this.$store.state.dictionary.buildinggroup;
        }
    },

    mounted() {
        this.current_page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
        this.search = this.$route.query.search ? this.$route.query.search : ''
        this.region = this.$route.query.region ? parseInt(this.$route.query.region) : ''
        this.district = this.$route.query.district ? parseInt(this.$route.query.district) : ''
        this.buildinggroup = this.$route.query.buildinggroup ? parseInt(this.$route.query.buildinggroup) : ''
        this.item_count = this.$route.query.item_count ? parseInt(this.$route.query.item_count) : 10
        if (this.region != '') {
            this.getDistrict()
        }
        this.__GET();
    },
    methods: {
        GeneratePasport(id) {

            this.loadDawer = true
            this.loading = true

            axios.post(`/building/specification/${id}/generate/`)
                .then(res => {
                    this.drawer = true
                    this.pdf = axios.defaults.baseURL.slice(0, -8) + res.data.pdf_link
                })
                .catch(e => {

                })
                .finally(() => {
                    this.loadDawer = false
                    this.loading = false

                })
        },
        downloadFile(file) {
            fetch(file)
                .then((resp) => resp.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    // the filename you want
                    a.download = 'PasportBino' || "file.json";
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
        },
        paginate(val) {
            this.current_page = val;
            this.__GET()
        },
        paginateSize(val) {
            this.current_page = 1
            this.current_page1 = 1
            if (this.search != '' || this.region != '' || this.district != '' || this.buildinggroup != '' || this.item_count != 10) {
                this.$router.push(`/statistica/building/${this.$route.params.type}?page=${this.current_page}&region=${this.region}&district=${this.district}&buildinggroup=${this.buildinggroup}&item_count=${this.item_count}&search=${this.search}`)
            } else {
                this.$router.push(`/statistica/building/${this.$route.params.type}?page=${this.current_page}`)
            }
            this.item_count = val;
            this.__GET();
        },
        __GET() {
            if (this.$route.params.type) {
                this.loading = true;
                let offset = this.item_count * (this.current_page - 1);
                if (this.search != '' || this.region != '' || this.district != '' || this.buildinggroup != '' || this.item_count != 10) {

                    this.$router.push(`/statistica/building/${this.$route.params.type}?page=${this.current_page}&region=${this.region}&district=${this.district}&buildinggroup=${this.buildinggroup}&item_count=${this.item_count}&search=${this.search}`)
                    .catch(e => {
                                            if (e.name !== 'NavigationDuplicated' && !e.message.includes('Avoided redundant navigation to current location')) {
                                                console.log(e)
                                            }
                                        });
                } else {
                    this.$router.push(`/statistica/building/${this.$route.params.type}?page=${this.current_page}`)
                    .catch(e => {
                                            if (e.name !== 'NavigationDuplicated' && !e.message.includes('Avoided redundant navigation to current location')) {
                                                console.log(e)
                                            }
                                        });
                }
                axios.get(`/building/specifications/?finalconclusion_id_in=${this.$route.params.type}&is_invest=${this.is_invest}&region__shared_id=${this.region}&district=${this.district}&building_group=${this.buildinggroup}&search=${this.search}&limit=${this.item_count}&offset=${offset}`)
                    .then((response) => {
                        this.buildsList = response.data.results;
                        this.page_count = Math.ceil(response.data.count / this.item_count);
                        this.current_page1 = this.$route.query.page ? parseInt(this.$route.query.page) : parseInt(this.$route.query.page)

                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
        routeTo(item, a) {
            if (item.buildingtype === 'NEW') {
                this.$router.push({
                    name: 'new-build',
                    params: {
                        obj: this.$route.params.id,
                        edit: a
                    },
                    query: {
                        id: item.id,

                    }
                })
            } else {
                this.$router.push({
                    name: 'exist-build',
                    params: {
                        obj: this.$route.params.id,
                        edit: a
                    },
                    query: {
                        id: item.id
                    }
                })
            }
        },
        getDistrict() {
            this.loading = true;
            axios.get(`/dictionary/district/?region__shared_id=${this.region}`)
                .then((response) => {

                    this.districts = response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        ChangeDistrict() {
            this.current_page = 1
            this.current_page1 = 1
            if (this.search != '' || this.region != '' || this.district != '' || this.buildinggroup != '' || this.item_count != 10) {
                this.$router.push(`/statistica/building/${this.$route.params.type}?page=${this.current_page}&region=${this.region}&district=${this.district}&buildinggroup=${this.buildinggroup}&item_count=${this.item_count}&search=${this.search}`)
            } else {
                this.$router.push(`/statistica/building/${this.$route.params.type}?page=${this.current_page}`)
            }
        },
        DownloadExel(id, name) {
            axios({
                url: `/building/generate/${id}/building/`,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', `${name}-Bino-${id}.xlsx`);
                document.body.appendChild(fileLink);

                fileLink.click();
            });
        },
        UserModal(a) {
            if (a != null && a.profile != null) {
                this.userData = a
                this.usermodal = true
            }
        },
        regionChanged() {
            this.current_page = 1
            this.current_page1 = 1
            if (this.search != '' || this.region != '' || this.district != '' || this.buildinggroup != '' || this.item_count != 10) {
                this.$router.push(`/statistica/building/${this.$route.params.type}?page=${this.current_page}&region=${this.region}&district=${this.district}&buildinggroup=${this.buildinggroup}&item_count=${this.item_count}&search=${this.search}`)
            } else {
                this.$router.push(`/statistica/building/${this.$route.params.type}?page=${this.current_page}`)
            }
            this.districts = [];
            this.district = "";
            this.getDistrict();
        },
        handleSelectionChange(val) {
            console.log(val)
            this.multipleSelection = val;
        },
        SaveMultipleSelection(){
            let data=[]
            if(this.multipleSelection && this.multipleSelection.length){
                this.multipleSelection.forEach(element => {
                    data.push( { "building": element.id, "is_invest": true } )
                });
                this.loading=true
                axios.post(`/building/building_to_invest/`, data)
                .then(() => {
                    this.$notify({
                        title: this.$t('data_saved'),
                        type: 'success'
                    });
                    this.__GET()
                    this.select_invest=false
                    this.is_invest=""
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loading = false
                })
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.top-header {
    width: 100%;
    padding: 20px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background: #fff;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top-header h1 {
   font-weight: 800;
    font-size: 25px;
    color: #43ABBA !important;
}

.title-second-header {
    font-size: 18px;
    font-weight: 600;
    color: #43ABBA !important;
}

</style>
